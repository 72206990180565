<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiFilterMenu }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <div class="app-customizer-header customizer-section py-3">
        <span :style="`color: ${$vuetify.theme.themes.light.primary};font-size: 18px`">
          <b>{{ $t('menu.hotels') }} </b>
          <span
            class="ml-5"
            :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: underline;cursor: pointer;font-size: 14px;`"
            @click="clearFiltros"
          >{{ $t('lbl.clearFilters') }}</span>
        </span><br />
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!--DESTINO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.destino') }}</span>
          <v-autocomplete
            v-model="filtersHotels.destino"
            :items="itemsDestino"
            :search-input.sync="searchDestino"
            hide-details
            hide-selected
            :label="$t('lbl.destino')"
            outlined
            dense
            clearable
            @change="refresh()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.destino') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>

        <!--TIPO DESTINO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.typeDestination') }}</span>
          <v-select
            v-model="filtersHotels.tipo_destino_id"
            :items="tiposDestino"
            item-text="name"
            item-value="id"
            :label="$t('menu.typeDestination')"
            outlined
            dense
            hide-details
            clearable
            @change="refresh()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.typeDestination') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <v-divider></v-divider>

        <!--CATEGORIAS-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.category') }}</span>
          <v-select
            v-model="filtersHotels.categoria_id"
            :items="categories"
            item-text="name"
            item-value="id"
            :label="$t('menu.category')"
            outlined
            dense
            hide-details
            clearable
            @change="refresh()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.category') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.name !== null">
                {{ item.name }}
                <span v-if="item.plus">PLUS</span>
              </span>
              <span v-if="item.name === null">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-rating
                      v-model="item.cant_estrellas"
                      color="primary"
                      size="30"
                      readonly
                    ></v-rating>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="mt-2"
                  >
                    <span v-if="item.plus">PLUS</span>
                  </v-col>
                </v-row>
              </span>
            </template>

            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-if="item.name !== null">
                  {{ item.name }}
                  <span v-if="item.plus">PLUS</span>
                </v-list-item-title>
                <v-list-item-title v-if="item.name === null">
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-rating
                        v-model="item.cant_estrellas"
                        color="primary"
                        class="mt-1"
                        readonly
                        size="30"
                      ></v-rating>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <p
                        v-if="item.plus"
                        class="pt-3"
                      >
                        PLUS
                      </p>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </div>
        <v-divider></v-divider>

        <!--OPERADOR-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.operador') }}</span>
          <v-autocomplete
            v-model="filtersHotels.operador_id"
            :items="itemsOperadores"
            :search-input.sync="searchOperador"
            hide-details
            hide-selected
            :label="$t('menu.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            clearable
            @click:clear="getMarcasAsociadas()"
            @change="refresh();setOperadores();"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>

        <!--MARCA ASOCIADA-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.marcaAsociada') }}</span>
          <v-autocomplete
            v-model="filtersHotels.marca_asociada_id"
            :items="itemsMarcas"
            :search-input.sync="searchMarcas"
            hide-details
            hide-selected
            :label="$t('menu.marcaAsociada')"
            outlined
            dense
            item-text="name"
            item-value="id"
            clearable
            @change="refresh();"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.marcaAsociada') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>

        <!--NOMBRE DEL AUTO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.hotel') }}</span>
          <v-text-field
            v-model="filtersHotels.name"
            :label="$t('lbl.hotel')"
            outlined
            dense
            single-line
            :append-icon="icons.mdiMagnify"
            @input="refresh()"
          >
          </v-text-field>
        </div>

        <v-divider></v-divider>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiFilterMenu, mdiClose, mdiStarOutline, mdiStar, mdiStarHalfFull, mdiMagnify,
} from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// 3rd Party

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    destinos: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    tiposDestino: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    categories: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    allOperadores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    allMarcasList: {
      type: Array,
    },
  },
  setup() {
    const isCustomizerOpen = ref(false)
    // eslint-disable-next-line object-curly-newline
    const {
      isDark,
      isRtl,
      themes,
    } = useAppConfig()

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isCustomizerOpen,

      isDark,
      isRtl,
      themes,

      // Perfect scrollbar options
      perfectScrollbarOptions,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      menuFromReserve: false,
      menuToReserve: false,
      menuFromService: false,
      menuToService: false,
      afiliadosList: [],
      afiliadosSearch: null,
      sellerList: [],
      sellerSearch: null,
      codesReserva: [],
      searchCodeReserva: null,
      icons: {
        mdiFilterMenu,
        mdiClose,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiMagnify,
      },
      permisos: sessionStorage.getItem('permisos_auth'),
      searchDestino: null,
      itemsDestino: [],
      searchOperador: null,
      itemsOperadores: [],
      allMarcas: [],
      itemsMarcas: [],
      searchMarcas: null,

      filtersHotels: {},
    }
  },
  computed: {
    ...mapState({
      // filtersHotels: state => state.app.filtersHotels,
    }),
  },
  watch: {
    searchDestino(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.itemsDestino = []
      }
    },
    searchOperador(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOperador(val.toLowerCase())
      } else {
        this.itemsOperadores = []
      }
    },
    searchMarcas(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterMarcas(val.toLowerCase())
    },
  },
  created() {
    this.allMarcas = this.allMarcasList
  },
  methods: {
    ...mapMutations([
      'filtrarCars',
      'filtersCotizadorHotelsResult',
      'clearCotizadorHotelsResultFilters',
      'clearFiltrosCars',
    ]),
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        this.itemsDestino = this.destinos.filter(e => e.toLowerCase())
      }
    },
    filterOperador(v) {
      this.itemsOperadores = []
      if (v === '') {
        this.itemsOperadores = []
      } else {
        this.itemsOperadores = this.allOperadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcas() {
      this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
    },
    setOperadores() {
      this.allMarcas = []
      if (this.allOperadores.filter(e => e.id === this.filtersHotels.operador_id)[0].marcas.length > 0) {
        this.allMarcas = this.allOperadores.filter(e => e.id === this.filtersHotels.operador_id)[0].marcas
        this.itemsMarcas = this.allMarcas
      }
    },
    getMarcasAsociadas() {
      this.allMarcas = this.allMarcasList
    },

    clearFiltros() {
      this.filtersHotels = {}
      this.$emit('refresh', { })
      this.isCustomizerOpen = false
    },
    refresh() {
      this.$emit('refresh', this.filtersHotels)
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
